@import './Components/variables'

.login-form
  .logo
    max-width: 140px

header
  background: none !important
  box-shadow: none !important

.custom-toolbar
  display: flex
  justify-content: space-between

.main-wrapper-login
  width: 100%
  
.main-wrapper
  margin-top: 100px
  padding: 24px
  background-color: $white
  flex-grow: 1
  overflow: unset

.mobile-menu
  position: fixed
  bottom: 0
  left: 0
  right: 0
  z-index: 20

  button
    background-color: $gray-100
    border-top: 1px solid $gray-200
    color: $black

    svg
      color: $primary
      margin-bottom: 4px

    &.Mui-selected, &:hover
      background-color: $primary
      color: $white
      fill: $white
      margin-top: -20px
      border-radius: 30px 30px 0 0
      border: none

      svg
        color: $white

.client-logo
  text-align: center
  margin: 32px 0
  img
    width: 60%

.custom-drawer
  .MuiListItem-root
    padding-bottom: 0
    padding-top: 0
    margin-bottom: 8px
    color: $white
    svg
      fill: $white
    
    &:hover,
    &.active
      background-color: $primary
      color: $black
      svg
        fill: $black

  .MuiButtonBase-root
    padding-bottom: 0
    padding-top: 0
    margin-bottom: 8px
    color: $white
    svg
      fill: $white
    
    &:hover,
    &.active
      background-color: $primary
      color: $black
      svg
        fill: $black

  .MuiListItemIcon-root
    min-width: 40px

  .MuiDrawer-paper
    background-color: $black

  .MuiChip-root
    color: $white
    background-color: $gray-800
    border: 0
    border-radius: 0 8px
    padding: 2px 4px
    line-height: 12px
    margin-left: 8px
    font-size: 10px
    font-weight: bold
    text-transform: uppercase
    height: auto

    .MuiChip-label
      padding: 0

  .experiment-icon
    width: 24px
    vertical-align: text-top
    background-color: $gray-800
    // border:1px solid $gray-800
    border-radius: 7px
    padding: 1px 4px
    margin-left: 8px
    
// Main config
h2, .MuiTypography-h1
  font-size: 24px !important
  line-height: 30px
  font-weight: 500 !important
  padding-top: 10px
  color: $gray-800

.header-login
  color: $gray-600
  margin-top: 16px
  .MuiTypography-h6
    font-size: 24px !important
    line-height: 30px
    font-weight: 500 !important
    padding-top: 10px
    color: $gray-800
    margin-top: 0
    padding-top: 0

  .header-login-info
    text-align: right

  .header-login-label
    font-size: 12px

    svg
      width: 12px
      vertical-align: middle


// Server page
.header-with-button
  margin-bottom: 32px

  h2
    font-size: 1.3em !important
    line-height: 1.4em !important
    margin: 0
    padding: 0

  button
    background-color: $primary
    vertical-align: middle

    &.MuiButton-outlined
      margin-right: 16px
      background-color: $white
      border-color: $primary
      color: $primary


// Modal
.custom-modal
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  width: 90%
  max-width: 800px
  background-color: $white
  box-shadow: 24px
  border-radius: 16px
  overflow: hidden

  .user-card-image-wrap
    text-align: center
    min-height: 320px

  .modal-image-carrousel
    width: 100%
    max-width: 800px
    max-height: 600px
    padding: 32px 13% 16px 16px
    flex-wrap: wrap
    flex-direction: column
    overflow-x: auto
    position: relative

    &::after
      content: " "
      display: block
      width: 15%
      height: 50%
      background: linear-gradient(270deg, rgba(255, 255, 255, 1) 24%, rgba(255,255,255,0) 100%)
      position: fixed
      right: 0
      top: 90px

    &.no-wrapper-frame
      padding-right: 16px

      &::after
        display: none

// universal header

.user-card
  border-radius: 16px !important

.user-card-header
  background-color: $gray-800
  padding: 8px 16px
  position: relative

  .MuiTypography-h3
    font-size: 20px
    line-height: 30px
    color: $white

.user-card-header-icons
  width: 150px
  text-align: right

  &.table-icons
    max-width: 40px
    margin: 0 auto

  i
    display: inline-block
    width: 30px
    height: 30px
    background-color: $primary
    line-height: 44px
    margin-left: 8px
    border-radius: 4px
    text-align: center

    &:hover
      background-color: darken($primary, 10% )
      cursor: pointer

    svg
      fill: $white


  .delete-icons
    background: none

    svg
      fill: $error

    &:hover
      background: $white


.user-card-content-details
  background-color: $gray-200
  list-style: none
  margin: 0
  padding: 16px

  li
    display: inline-block
    font-size: 14px
    line-height: 24px
    color: $gray-800
    border-right: 1px solid $gray-400
    margin-right: 16px
    padding-right: 16px

    &:last-child
      border: none

// modal form
.modal-form-content
  padding: 16px

  button
    width: 100%
    background-color: $primary
    color: $white
    padding: 8px 16px

  .icon-success
    // width: 40px
    // height: 40px
    text-align: center
    font-size: 16px
    color: $primary
    svg
      width: 50px
      height: 50px
      background-color: $primary
      padding: 4px
      fill: $white
      border-radius: 100%
      vertical-align: middle
      margin-right: 16px

.data-table-wrapper
  display: grid
  width: 100%
  margin: 32px 0


  // sidebar
@media (min-width: 768px)
  .main-wrapper
    margin-left: 240px


.inside-jobs // remove sidebar for specific itens
  margin-left: -240px
  height: 90vh

// default box ?
.default-box
  width: 44%
  margin: 0 2%
  border-radius: 16px
  overflow: hidden

  .MuiTypography-h2
    background-color: $gray-800
    padding: 8px 16px
    position: relative
    font-size: 20px !important
    line-height: 30px
    color: $white

  table
    th, td
      padding: 8px
      text-align: center
    tr th
      background-color: $primary
      color: $white
    tr:nth-child(even)
      background-color: $gray-100

  .big-numbers
    width: 100%
    display: inline-block
    font-size: 43px !important
    text-align: center
    background-color: $primary
    color: $white



// CSS RESET
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active
  -webkit-box-shadow: 0 0 0 30px white inset !important



// Terminals?!
.custom-modal-device-search
  width: 100%
  max-width: 400px
  
  .MuiTypography-h2
    font-size: 24px
    padding: 0 16px
    margin: 16px 0
    color: $primary

  table
    .MuiTableRow-root
      &:nth-child(odd)
        background-color: $primary-100

  .device-search-bottom
    margin: 16px 0
    text-align: center
    width: 100%

    button
      border-color: $primary
      color: $primary
      padding: 8px 16px
    
.data-table-wrapper
  .MuiDataGrid-row
    &:nth-child(odd)
      background-color: $gray-200

.single-status
  svg
    font-size: 12px
    margin-right: 8px
  .icon-active
    fill: $primary
  .icon-warning
    fill: $warning
  .icon-inactive
    fill: $error

// mobile
@media (max-width: 768px)
  .header-with-button
    flex-wrap: wrap
    h2
      width: 100%
    .MuiBox-root
      width: 100%
      margin-top: 16px

    button
      font-size: 11px
      width: 44%
      margin: 0 2%



// Terminal Analysis
.device-analysis-wrapper

.device-analysis
  margin: 32px 0 80px 0
  th
    font-size: 11px

.device-analysis-leds
  border: 1px solid $gray-200
  border-radius: 16px
  margin: 32px 0
  padding: 16px
  display: flex
  justify-content: space-between
  flex-wrap: wrap

  h3
    text-transform: uppercase
    font-size: 12px
    font-weight: bold
    width: 100%
    margin-bottom: 8px
  button
    width: 24%
    font-size: 11px
    