@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap')

//Primary colors
$primary: #64c800

$primary-100: mix(white, $primary, 90%)
$primary-200: mix(white, $primary, 70%)
$primary-300: mix(white, $primary, 50%)
$primary-400: mix(white, $primary, 30%)
$primary-500: $primary
$primary-600: mix(black, $primary, 20%)
$primary-700: mix(black, $primary, 40%)
$primary-800: mix(black, $primary, 60%)
$primary-900: mix(black, $primary, 80%)

//Secondary colors
$secondary: #046e58

$secondary-100: mix(white, $secondary, 90%)
$secondary-200: mix(white, $secondary, 70%)
$secondary-300: mix(white, $secondary, 50%)
$secondary-400: mix(white, $secondary, 30%)
$secondary-500: $secondary
$secondary-600: mix(black, $secondary, 20%)
$secondary-700: mix(black, $secondary, 40%)
$secondary-800: mix(black, $secondary, 60%)
$secondary-900: mix(black, $secondary, 80%)

// Gray colors
$gray: #91cb98

$gray-100: mix(white, $gray, 90%)
$gray-200: mix(white, $gray, 70%)
$gray-300: mix(white, $gray, 50%)
$gray-400: mix(white, $gray, 30%)
$gray-500: $gray
$gray-600: mix(black, $gray, 20%)
$gray-700: mix(black, $gray, 40%)
$gray-800: mix(black, $gray, 60%)
$gray-900: mix(black, $gray, 80%)

//Basic colors
$off-white: #fdfdfd
$black: #006437
$white: #fff


//Error color
$error: #c90131

//Warning color
$warning: #ffff00

//Typography 

@mixin titleLarge 
    font-family: Roboto
    font-size: 30px
    font-weight: 700
    line-height: 45px
    text-align: left

@mixin titleMedium 
    font-family: Roboto
    font-size: 24px
    font-weight: 700
    line-height: 40px
    text-align: left

@mixin titleSmall 
    font-family: Roboto
    font-size: 24px
    font-weight: 500
    line-height: 36px
    text-align: left

@mixin subtitle 
    font-family: Roboto
    font-size: 20px
    font-weight: 400
    line-height: 30px
    text-align: left

@mixin bodyTextLarge 
    font-family: Roboto
    font-size: 18px
    font-weight: 400
    line-height: 30px
    text-align: left

@mixin bodyTextSmall 
    font-family: Roboto
    font-size: 16px
    font-weight: 400
    line-height: 24px
    text-align: left
